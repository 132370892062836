(function () {
	'use strict';

	// clamp number between two values
	window.clampNumber = (num, min, max) => Math.min(Math.max(num, min), max);

	// magical markup used to generated a container and column
	// needed to measure the width of a column and set it to a CSS var so it can be accessed from any context
	const columnLayout = `
		<div class="container">	
			<div class="row">
				<div id="measure-col-1" class="col-1">
					<div id="measure-col-half" class="w-50"></div>
				</div>
			</div>
		</div>
	`;
	const div = document.createElement('div');
	div.id = 'measuring-div';
	div.innerHTML = columnLayout;
	document.head.parentNode.append(div);

	window.setColumnCSSVars = () => {
		const col1 = div.querySelector('#measure-col-1');
		const lineCheckerEl = div.querySelector('#measure-col-half');

		const colWidthStyles = getComputedStyle(col1);
		const colWidth = `${
			parseInt(colWidthStyles.width) - parseInt(colWidthStyles.paddingLeft) - parseInt(colWidthStyles.paddingRight)
		}px`;
		document.documentElement.style.setProperty('--col-width', colWidth);

		const currentBreakpoint = window
			.getComputedStyle(document.querySelector('html'), ':before')
			.getPropertyValue('content')
			.replace(/["']/g, '');

		// will offset the line by a half-column on desktop, and otherwise align to left of first column
		const isDesktop = ['screen-lg', 'screen-xl'].includes(currentBreakpoint);
		const lineOffset = isDesktop
			? lineCheckerEl.getBoundingClientRect().right
			: lineCheckerEl.getBoundingClientRect().left;
		document.documentElement.style.setProperty('--line-offset', `${lineOffset}px`);
	};

	window.setHeaderOpacityVars = () => {
		// transitioning the logo opacity based on scroll, with a bit of leeway so it's not from the absolute top of the page
		// opacity number also manually clamped between 0 and 1
		document.documentElement.style.setProperty(
			'--header-desktop-logo-opacity',
			`${window.clampNumber(1.6 - window.scrollY / 60, 0, 1)}`
		);
		document.documentElement.style.setProperty(
			'--header-mobile-logo-opacity',
			`${window.clampNumber(-1.6 + window.scrollY / 60, 0, 1)}`
		);
		// this CSS var only gets applied on Brandenburg One homepage
		document.documentElement.style.setProperty(
			'--header-background-opacity',
			`${window.clampNumber(-1.6 + window.scrollY / 60, 0, 1)}`
		);
	};

	window.setColumnCSSVars();
	window.setHeaderOpacityVars();
})();
